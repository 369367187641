.contentFirstWrapper {
  width: 100%;
  height: calc(100% - 82px);
  margin-bottom: 40px;

  background: #ffffff;
  border-radius: 12px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 32px;
    height: calc(100% - 114px);
  }

  @media screen and (max-width: 480px) {
    margin-top: 20px;
  }

  @media only screen and (min-aspect-ratio: 11/5) {
    margin-bottom: 20px;
  }
}

.fullScreenContentWrapper {
  & > .contentSecondWrapper {
    padding: 0;
  }
}

.contentSecondWrapper {
  height: 100%;
  padding: 38px 24px;

  @media screen and (max-width: 480px) {
    padding: 0;
  }

  & > div {
    height: 100%;
    padding-right: 32px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #a6a6a6;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #757575;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &::-webkit-scrollbar-track-piece {
      background: #a6a6a6;
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.title {
  text-align: center;
  margin-bottom: 32px;
}

.text {
  white-space: pre-wrap;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media only screen and (min-aspect-ratio: 11/5) {
    font-size: 12px;
    line-height: 16px;
  }
}
