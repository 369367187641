.pageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1644px;
  gap: 20px;

  width: 100%;
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 60px;

  @media screen and (max-width: 767px) {
    padding: 24px 20px;
  }
}

.searchRow {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;

  > div {
    width: 100%;
  }

  > button {
    width: 64px !important;
    max-width: 64px;
    height: 48px;
    border: 1px solid #e0e3e7 !important;
    border-radius: 12px !important;
  }
}
