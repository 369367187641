.inviteContainer {
  display: flex;
  min-width: min-content;
  width: 415px;
  height: max-content;
  flex-direction: column;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
  align-content: center;
  padding: 16px 20px 26px 20px;

  @media screen and (max-width: 1300px) {
    width: 250px;
  }
}

.inviteHeader {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.inviteTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}

.inviteText {
  width: 100%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 26px;
}

.inviteImgRow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  > div {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    line-height: unset !important;
  }
}

.inviteHeaderImg {
  cursor: pointer;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.7;
  }
}

.inviteButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background: linear-gradient(3deg, rgb(0, 214, 201), rgb(24, 57, 122));
  border-radius: 4px;
}

.openModalButton {
  //styleName: Button/ Mob textButton 12px-20px-normal-700;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  flex: none;
  order: 1;
  flex-grow: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.inviteIMGWrapper {
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  margin-right: 10px;
}

.inviteIMG {
  filter: brightness(0) invert(1);
  svg > path {
    fill: #fff !important;
    stroke: #fff !important;
  }
}
