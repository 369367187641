.ViewKeyTitleBlock {
  .ViewKeyTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
}
.ViewKeyContent {
  margin-top: 16px;
  .ViewKeyDescription {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
    a {
      margin-left: 6px;
      text-decoration: underline;
    }
  }
  .ViewKeyPublic {
    margin-bottom: 16px;
    .ViewKeyPublicTitle {
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
  .ViewKeyPrivate {
    .ViewKeyPrivateTitle {
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .ViewKeyPrivateDescription {
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
  .ViewKeyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(239, 239, 239, 1);
    padding: 10px 18px;
    border-radius: 12px;
    p {
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
    }
    .copyIcon {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}