.listMemberWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.listMemberSecondWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatarWrapper {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.statusWrapper {
  position: absolute;
  right: -2px;
  top: -2px;
  padding: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffff;
}

.online,
.busy,
.offline {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.online {
  background-color: #4caf50;
}

.busy {
  background-color: #757575;
}
.offline {
  background-color: #d32f2f;
}

.name {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #212121;
}

.addIcon {
  cursor: pointer;
}
