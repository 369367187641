.documentationBlock {
  background-color: rgba(45, 90, 168, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 60px;
  margin-top: 26px;
  border-radius: 0 0 14px 14px;
  h2 {
    color: #fff;
    font-size: 25px;
    line-height: 48px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }
  .whiteButton {
    background-color: white !important;
    color: rgba(45, 90, 168, 1) !important;
    border-color: white !important;
  }
}