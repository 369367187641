$breakpoint-tablet: 1024px;
$breakpoint-mean-mobile: 663px;
$breakpoint-mobile: 375px;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat' !important;
}

.supportChat {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.chatHeaderBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.supportChatButton {
  right: 50px;
  bottom: 32px;
  cursor: pointer;
  position: fixed;
  z-index: 999;
  border: none;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.fullScreenWindow {
  width: 100vw !important;
  height: 100vh !important;
  max-height: 100vh !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  border-radius: 0 !important;
}

.fullScreenWindow .messageContent {
  max-width: 80vw;
}

.messageSupport .extraLinks {
  max-width: 80vw;
  width: auto !important;
  overflow-x: auto !important;
}

.chatWindow {
  width: 500px;
  right: 50px;
  bottom: 32px;
  position: fixed;
  z-index: 999;
  max-height: 810px;
  height: 810px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px 12px;
  overflow: hidden;

  .chatWindowHeader {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    gap: 12px;

    .chatWindowHeaderContent {
      display: flex;
      width: 105%;
      justify-content: space-between;
    }

    .chatHeaderTitle {
      text-align: start;
      margin-right: 15px;

      h2 {
        font-style: normal;
        color: #ffffff;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
      }

      p {
        font-style: normal;
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .closeChatWindow,
    .fullScreenIcon {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .chatWindowBody {
    height: calc(100% - 113px); // Minus header height
    background: #ffffff;
    position: relative;

    .chatWindowMessage {
      overflow-y: auto;
      height: calc(100% - 99px); // Minus footer height
      margin: 3px 8px 0 0;
      padding-left: 20px;
      &::-webkit-scrollbar {
        width: 4px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: rgba(239, 239, 239, 0.6);
      }

      &::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 4px;
      }
    }

    .messagePrinted {
      height: 17px;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: rgba(51, 51, 51, 0.6);
        padding: 2px 0;
      }
    }

    .chatWindowFooter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      border-top: 1px solid #efefef;

      form {
        display: flex;
        width: 100%;
        column-gap: 24px;
        input[type='text'] {
          padding: 10px;
          border-style: none;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #212121;
          font-family: 'Montserrat';
          &::placeholder {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #757575;
          }

          &:focus {
            outline: none;
          }

          &:disabled {
            background: none;
          }
        }

        .messageSendBtn {
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 16px;
          gap: 10px;
          border: none;
        }
      }
    }
  }
}

.messageSupport {
  display: flex;
  justify-content: center;
  margin: 16px 0px;
  align-items: flex-start;
  flex-direction: column;

  .message {
    word-wrap: break-word;
    justify-content: flex-start;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 12px 12px 12px 0px;
    padding: 8px 20px;
    display: flex;
    gap: 10px;
    font-style: normal;
    text-align: left;

    .linksMessage {
      border-top: 1px dashed #c4c4c4;
      display: flex;
      align-items: center;
      padding: 8px 0;
      gap: 2px;

      a,
      span {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 4px 12px;
        gap: 8px;
        background: #14711d;
        border-radius: 12px;
        color: #efefef;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .extraLinks {
    border-top: 1px dashed #c4c4c4;
    width: 250px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 8px 0;
    margin-top: 8px;
    gap: 2px;

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #efefef;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8;
    }

    a {
      width: 120px;
      height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 0;

      padding: 4px 12px;
      gap: 8px;
      background: #143471;
      border-radius: 12px;
      color: #efefef;
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }

  button {
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
  }

  .reateMessage {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 20px;
    gap: 10px;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    div {
      background: #ffffff;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 8px 20px;
      gap: 20px;
    }
  }
}

.messageUser {
  display: flex;
  justify-content: flex-end;
  margin: 16px;

  .message {
    word-wrap: break-word;
    justify-content: flex-start;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 12px 12px 0px 12px;
    padding: 8px 20px;
    display: flex;
    gap: 10px;
    font-style: normal;
    text-align: left;
  }
}

.messageAutomatic {
  display: flex;
  justify-content: flex-end;
  margin: 8px;

  .message {
    word-wrap: break-word;
    cursor: pointer;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 8px 20px;
  }
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.messageContent {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 237px;
  user-select: text !important;
  color: #212121;

  a {
    max-width: 100%;
    color: #2d76fc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.messageTime {
  margin: 0;
  margin-top: 2px;
  display: flex;
  align-items: flex-end;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
    margin: 0;
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .supportChatButton,
  .chatWindow {
    right: 16px;
    bottom: 80px;
  }
}

@media only screen and (max-width: $breakpoint-mean-mobile) {
  .chatWindow {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0;
    bottom: 0;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .fullScreenIcon {
    display: none;
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .messageContent {
    max-width: 201px;
  }

  .chatWindow {
    .chatWindowBody {
      height: calc(100% - 84px); // Minus header height

      .chatWindowMessage {
        height: calc(100% - 88px);
      }

      .chatWindowFooter {
        padding: 6px 12px;

        form {
          .messageSendBtn {
            padding: 16px;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .chatWindowHeader {
      padding: 0px 24px;

      .chatWindowHeaderLogo {
        width: 40px;
        height: 40px;
      }

      .chatHeaderTitle {
        h2 {
          margin: 0;
          font-size: 16px;
          line-height: 24px;
        }

        p {
          margin: 0;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  .supportChatButton {
    right: 12px;
    padding: 16px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media only screen and (max-height: 850px) and (min-width: $breakpoint-mean-mobile) {
  .chatWindow {
    height: calc(100% - 100px);
  }
}

.tooltip {
  display: none;
  background-color: rgba(97, 97, 97, 0.92);
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1000;
  bottom: 48px;
  inset: 0 auto auto 0;
  margin: 0;
  width: fit-content;
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: -9.5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(97, 97, 97, 0.92) transparent transparent transparent;
}
