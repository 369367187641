.modalDialogBlock {
  padding: 0 10px !important;
  height: 100%;
}

.parent {
  &:hover {
    color: #143471;

    .nestedLink {
      display: block;
    }
  }
}

.divider {
  background: #efefef;
  width: 100%;
  height: 1px;
  margin: 12px 0;
}

.linksRow {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.disabledLink {
  color: #909090 !important;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  margin-bottom: 12px;
  cursor: pointer;
}

@media screen and (max-width: 375px) {
  .modal_dialog {
    width: 260px;
  }

  .modal_dialog .modal_dialog_block {
    padding: 0;
  }

  .linksRow {
    font-size: 14px;
    line-height: 24px;
  }
}
