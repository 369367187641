.shareModalContent {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;

  .copyBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 36px;

    .copyInput {
      border-color: red;
    }

    .copyBlockButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 9px;
      cursor: pointer;
      min-width: 76px;
      width: 76px;
      height: 57px;

      .copyBlockButtonGreenText {
        color: #2d8342;
      }
    }
  }

  .copyBlockButtonText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  .socialMediaList {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
    @media screen and (max-width: 460px) {
      grid-template-columns: repeat(auto-fill, minmax(131px, 1fr));
    }
    @media screen and (max-width: 355px) {
      grid-template-columns: repeat(auto-fill, minmax(213px, 1fr));
    }
    max-width: 456px;
    row-gap: 20px;
    column-gap: 32px;

    .socialMedia {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 12px;
      max-width: 90px;
      width: 90px;
      cursor: pointer;

      .copyBlockButtonText {
        text-align: center;
      }
    }
  }
}
