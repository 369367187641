.decarbonizatorBlock {
  position: relative;
  padding: 24px 60px;
  margin-top: 30px;
  .decarbonizatorInfo {
    max-width: 600px;
    margin-bottom: 24px;
  }
  .decarbonizatorTitle {
    display: flex;
    align-items: center;
    h2 {
      margin-left: 8px;
      font-size: 38px;
      font-weight: 600;
      line-height: 48px;
      margin-bottom: 4px;
    }
    span {
      cursor: pointer;
    }
  }
  .decarbonizatorInfo {
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 24px;
    }
  }
  .keyButtons {
    display: flex;
    gap: 16px;
  }
  .decarbonizatorImage {
    position: absolute;
    right: 0;
    top: -30px;
  }
}