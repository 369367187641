@import '../../styles/_mixins.scss';

.modalContainer {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90%;
  padding: 56px 70px;
  backdrop-filter: blur(20px);
  border-radius: 10px;

  @include for-desktop-up-max_1440 {
    max-width: 100% !important;
    width: 70%;
    padding: 30px;
  }

  @include for-desktop-up-max_1200 {
    width: 80%;
  }

  @include for-mobile-max_767 {
    width: 90%;
  }

  @include for-narrow {
    width: 60%;
  }
}

.consentContainer {
  @include for-mobile-max_480 {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
    background-color: #ffffff !important;
    justify-content: space-between;
  }
}

.closeIconWrapper,
.errorCloseIconWrapper,
.fullscreenIconWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  cursor: pointer;
  transition: 0.2s;

  &:hover img {
    transform: scale(1.2);
  }
}

.iconsWrapper {
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.fullscreenIconWrapper {
  display: flex;
  margin-right: 13px;

  @include for-mobile-max_480 {
    display: none;
  }
}

.closeIconWrapper {
  display: flex;
  align-self: center;
}

.mapContainer {
  width: 90%;
  height: 90%;
  padding: 24px;
}

.errorModalContainer {
  position: relative;
  border-radius: 12px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  height: 50%;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include for-mobile-max_767 {
    width: 70%;
  }

  @include for-mobile-max_480 {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }

  .errorCloseIconWrapper {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

.passwordCloseIconWrapper {
  position: absolute;
  right: 31.5px;
  top: 23.5px;
  cursor: pointer;
}

.fullScreen {
  width: 65%;
  height: 80%;
  padding: 32px;

  @include for-mobile-max_767 {
    width: 90%;
  }

  @include for-mobile-max_480 {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
    background-color: #ffffff !important;
    justify-content: space-between;
  }
}

.fullScreenActive {
  height: 95%;
  padding: 0;
  background: transparent !important;
}
