.decarbonizatorBlock {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 24px 60px;
  margin-top: 30px;
  .decarbonizatorInfo {
    max-width: 600px;
  }
  .decarbonizatorTitle {
    display: flex;
    align-items: center;
    h2 {
      margin-left: 8px;
      font-size: 38px;
      font-weight: 600;
      line-height: 48px;
      margin-bottom: 4px;
    }
    span {
      cursor: pointer;
    }
  }
  .decarbonizatorInfo {
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 24px;
    }
  }
  .decarbonizatorImage {
    position: absolute;
    right: 0;
    top: -30px;
}


}

.benefitsOfDecarbonizer {
  padding: 24px 60px;
  margin-top: 70px;
  h3 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
  }
  .benefitsList {
    margin-top: 24px;
    .benefitsListRow {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      .benefitsListItem {
        display: flex;
        align-items: flex-start;
        img {
          width: 80px;
        }
        div {
          width: 300px;
          margin-left: 20px;
          p {
            white-space: nowrap;
            &:first-child {
              font-size: 20px;
              font-weight: 600;
              line-height: 32px;
              margin-bottom: 10px;
            }
            &:last-child {
              font-size: 17px;
              line-height: 29px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
  
.documentationBlock {
  background-color: rgba(45, 90, 168, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 60px;
  h2 {
    color: #fff;
    font-size: 25px;
    line-height: 48px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }
  .whiteButton {
    background-color: white !important;
    color: rgba(45, 90, 168, 1) !important;
    border-color: white !important;
  }
}

.questionsBlock {
  padding: 50px 60px;
  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 24px;
  }
  .questionsList{
    display: flex;
    flex-direction: column;
    gap: 24px
  }
  .questionsItem {
    box-shadow: 18px 15px 35px 0px rgba(0, 0, 0, 0.09);
    border-radius: 20px;
    padding: 32px 40px;

    .questionsTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
      img {
        width: 19px;
      }
    }
  }
}

.content {
    transition: all 0.3s ease-out;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    cursor: pointer;

    &.expanded {
      opacity: 1;
      margin-top: 15px;
      margin-bottom: 15px;
    }
}