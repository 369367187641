.navbarLinkRow {
  font-size: 0.97vw;
  font-weight: 400;
  transition: 0.25s;
  cursor: pointer;
  color: #555555;
  white-space: nowrap;
}

.navbarLink {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #555555;

  &:active {
    color: #143471;
  }

  &:hover {
    color: #143471;
  }
}

.navbarLinkActive {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  color: #ffff;
  border-radius: 0;
  padding: 20px;
  background: #143471;
  text-transform: uppercase;
}

.navbarLinkImage {
  transition: 0.2s;
  margin-bottom: 5px;
  height: 20px;
}

.navbarLinktext {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  transition: 0.25s;
  cursor: pointer;
  white-space: nowrap;
}
