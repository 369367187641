.container {
  display: flex;
  min-width: min-content;
  width: 396px;
  height: max-content;
  background-color: white;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-bottom: none !important;
  border-radius: 12px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;

  @media screen and (max-width: 1300px) {
    width: 250px;
  }
}

.tableMain {
  width: 376px;
}

.table {
  border-bottom: none;
  border-top: none;
  line-height: 0.8rem;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  box-shadow: none !important;
  padding: 0px;
  border-bottom: none;
  outline: none;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  padding-right: 10px;
  width: 100%;
}

.headTitle {
  display: flex;
  align-items: center;
}

.image {
  width: 13px;
  height: 13px;
}

.title {
  padding-left: 10px;
  margin-top: 2px;
  margin-right: 12px;
  cursor: pointer;
  color: #000000;
  text-decoration: none;

  &:hover {
    color: #000000;
  }
}

.year {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #555555;
}

.subtitleRow {
  padding-bottom: 12px;
  border-bottom: 1px solid #efefef;
  width: 100%;
  margin-bottom: 8px;
}

.subtitle {
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  text-decoration: none;

  &:hover {
    color: #555555;
  }
}

.control {
  display: flex;
  column-gap: 17px;
  align-items: center;
}

.sort {
  display: flex;
  align-items: center;
  column-gap: 9px;
  position: relative;
}

.sortMenu {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px;
  width: 226px;
  position: absolute;
  top: -20px;
  left: -90px;
  z-index: 51;
}

.sortMenuWrap {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 50;
  cursor: default;
}

.sortMenuRow {
  margin-left: 0;
  padding-left: 0;
  padding: 24px 40px;
  transition: opacity 0.2s ease-in;

  li {
    list-style-type: none;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-bottom: 36px;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 1;
    }
  }

  li:last-child {
    margin-bottom: 0;
  }
}

.sortTitle {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  width: 100%;
  margin-top: 2px;
}

.sortTitleRow {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px 0px 20px;
}

.sortImg {
  transform: rotate(3.142rad);
}

.settings {
  cursor: pointer;
}
