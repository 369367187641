.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;

  @media screen and (max-width: 480px) {
    justify-content: center;
    gap: 20px;
  }
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }

  & + label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #757575;
    border-radius: 2px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 60%;
    cursor: pointer;
  }

  &:checked + label::before {
    background-color: #757575;
  }

  &:disabled + label::before {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
}

.btn {
  padding: 13px 28px;
  gap: 28px;
  width: 168px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  background: #18397a;
  color: #ffffff;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #8c9dbd;
    border: none;
    cursor: not-allowed;
  }
}
