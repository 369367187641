.container {
  padding: 24px 60px;
}

.mainBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  
  .mainBlockInfo {
    max-width: 480px;
    text-align: left;
    margin-right: 15px;
      h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
        color: rgba(33, 33, 33, 1);
        margin-bottom: 1rem;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        color: rgba(33, 33, 33, 1);
        line-height: 24px;
        margin-bottom: 1rem;
      }
      ul {
        list-style-type: disc;
        padding-left: 1.5rem;
        margin-bottom: 1rem;
      }
      ul li {
        margin-bottom: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        color: rgba(33, 33, 33, 1);
        line-height: 24px;
      }
  }
}

.stepsBlock {
  margin-top: 100px;
  h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  .steps {
    margin-left: 16px;
  }

  .step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    position: relative;
  }

  .stepArrow {
    position: absolute;
    left: -10px;
    bottom: -25px;
  }

  .stepNumber {
    flex-shrink: 0;
    width: 43px;
    height: 43px;
    background-color: rgba(45, 90, 168, 1);
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 400;
    margin-right: 15px;
    position: relative;
  }

  .stepNumber::after {
    content: '';
    width: 2px;
    height: 70px;
    background-color: #d1d1d1;
    position: absolute;
    left: 50%;
    top: 36px;
    transform: translateX(-50%);
    z-index: -1;
  }

  .step:last-child .stepNumber::after {
    display: none;
  }

  .stepContent h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    color: rgba(33, 33, 33, 1);
    margin: 0;
  }

  .stepContent p {
    font-size: 17px;
    font-weight: 500;
    color: rgba(22, 28, 45, 1);
    margin: 0.5em 0 0;
    line-height: 29px;
  }
}

.listBlock {
  margin-top: 100px;
  h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
  }
  .decarbonizatorBlock {
    box-shadow: 18px 15px 35px 0px rgba(0, 0, 0, 0.09);
    border-radius: 16px;
    padding: 26px;
    margin-top: 12px;
    cursor: pointer;
    &:hover {
      box-shadow: 18px 15px 35px 0px rgba(0, 0, 0, 0.15);
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-top: 24px;
      margin-bottom: 16px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.buttonBook {
  margin-top: 40px;
  background-color: rgba(45, 90, 168, 1) !important;
}