.menuWrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30%;
  display: none;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 0px 12px 12px 0px;
  opacity: 0;
  padding: 16px;
}

@-webkit-keyframes menuOpen {
  100% {
    background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
    backdrop-filter: blur(4px);
    opacity: 1;
  }

  0% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes menuOpen {
  100% {
    background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
    backdrop-filter: blur(4px);
    opacity: 1;
  }

  0% {
    visibility: hidden;
    opacity: 0;
  }
}

.visible {
  display: block;
  opacity: 1;
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  color: #212121;
}

.membersList {
  margin: 16px 25px 0 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.close {
  cursor: pointer;
}
.searchInputContent {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px;
  flex: none;
  order: 3;
  flex-grow: 0;
  box-shadow: none;
  margin-top: 20px;
}
