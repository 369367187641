.searchBox {
  height: 40px;
  width: 100%;
  background-color: #f9f9f9;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 12px;
  @media only screen and (max-width: 1200px) {
    width: 80%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    width: 60%;
  }
}

.searchImg {
  margin: 14px;
  width: 17.5px;
  height: 17.5px;
}

.search {
  background-color: #f9f9f9;
  height: 24px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  @media only screen and (max-width: 1500px) {
    width: 50%;
  }
}

.searchInput {
  font-family: 'Montserrat';
  border: #f9f9f9;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  @media only screen and (max-width: 1500px) {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
}

@media screen and (max-width: 520px) {
  .searchBox {
    width: 179px;
  }

  .searchInput {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .searchImg {
    width: 11px;
    height: 11px;
    margin: 0px 20px 2px 11px;
  }
}

@media screen and (max-width: 375px) {
  .searchBox {
    width: 100%;
  }
}
