.navbarItem {
  height: 48px;
  width: 48px;
  transition: 0.25s;
  cursor: pointer;
  color: #555555;
  white-space: nowrap;
}

.navbarLink {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding: 0 15px !important;

  &:active {
    color: #143471;
  }

  &:hover {
    color: #143471;
  }
}

.navbarLinkActive {
  background: #143471;
}

.linkIcon {
  transition: 0.2s;
  margin-bottom: 5px;
  width: 20px;
  height: 20px;
}

.linkIconActive {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.navbarLinktext {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  transition: 0.25s;
  cursor: pointer;
  white-space: nowrap;
}

.menuButton {
  padding: 11px;
  cursor: pointer;
}

.btnBox {
  @media screen and (max-width: 865px) {
    padding: 0 !important;
  }
}

.menuItem {
  min-height: auto !important;
  padding: 0 !important;
}
