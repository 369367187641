.modalContainer {
  max-width: 604px;

  .label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 8px;

    .labelTitle {
      color: #212121;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .activeLabelTitle {
      color: #143471;
    }

    .labelDescription {
      color: #555;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
}
