@import './fonts.scss';
@import 'react-phone-input-2/lib/style.css';

html,
body {
  font-family: 'Montserrat', sans-serif;
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;

  :hover {
    color: inherit;
    text-decoration: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#__next {
  height: 100%;
}

input:autofill {
  background-color: transparent;
  transition: all 50000s ease-in-out;
}

input:-webkit-autofill {
  background-color: transparent;
  transition: all 50000s ease-in-out;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;

  // Allows to add padding and border to HTML elements without increasing their size.
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  height: 100%;
  margin: 0;
  font-size: 14px;
}
