.rightColumn {
  position: sticky;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 396px;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.widgetItem {
  margin-bottom: 16px;

  > div {
    width: 396px !important;

    @media screen and (max-width: 1300px) {
      width: 270px !important;
    }
  }
}

.drawerWrapper {
  position: sticky !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 2;
  width: 65px !important;

  > div {
    z-index: 1;
  }

  > div > div {
    position: relative;
    border-radius: 12px;
  }
}

.drawerList {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  max-height: calc(90vh - 200px);
  margin: 0 5px;
}

.drawerItem {
  margin: 20px 15px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin: 0;
    white-space: normal !important;
    width: 340px !important;
    min-width: 340px !important;
  }
}
