@import '../../../styles/_mixins.scss';

.errorModalWrapper {
  position: fixed;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-tap-highlight-color: transparent;
  z-index: 100;
  background-color: rgb(255, 255, 255);
}

.orientationModalWrapper {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  background-color: #212121;

  @media screen and (max-width: 1024px) and (orientation: landscape) {
    opacity: 1;
    visibility: visible;
  }

  & .title {
    color: #ffffff;
  }
}

.orientationModalWrapper.keyboardVisible {
  display: none !important;
}

.orientationModalWrapper.desktop {
  display: none !important;
}

.title {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #212121;

  // @include for-mobile-max_767 {
  //   font-size: 20px;
  //   line-height: 30px;
  // }
}

.subTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;

  color: #212121;

  @include for-mobile-max_767 {
    font-size: 14px;
    line-height: 26px;
  }
}

.btn {
  margin-top: 16px;
  padding: 0px 20px;
  background: #212121;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  line-height: 48px;

  @include for-mobile-max_767 {
    font-size: 14px;
    line-height: 40px;
  }
}
