// .decarbonizatorBlock {
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   padding: 24px 60px;
//   margin-top: 30px;
//   .decarbonizatorInfo {
//     max-width: 600px;
//   }
//   .decarbonizatorTitle {
//     display: flex;
//     align-items: center;
//     h2 {
//       margin-left: 8px;
//       font-size: 38px;
//       font-weight: 600;
//       line-height: 48px;
//       margin-bottom: 4px;
//     }
//   }
//   .decarbonizatorInfo {
//     p {
//       font-size: 18px;
//       font-weight: 400;
//       line-height: 24px;
//       margin-top: 24px;
//     }
//   }
//   .decarbonizatorImage {
//     position: absolute;
//     right: 0;
//     top: -30px;
// }


// }

.authDecarbonizatorBlock {
  margin-top: 80px;
  h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
  }
}

.decarbonizatorBlock {
    box-shadow: 18px 15px 35px 0px rgba(0, 0, 0, 0.09);
    border-radius: 16px;
    padding: 26px;
    margin-top: 24px;
    cursor: pointer;
    &:hover {
      box-shadow: 18px 15px 35px 0px rgba(0, 0, 0, 0.15);
    }
    .decarbonizatorBlockTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        width: fit-content;
        height: fit-content;
        background-color: rgba(76, 175, 80, 1);
        padding: 5px 12px;
        border-radius: 12px;
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #fff;
        }
      }
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-top: 24px;
      margin-bottom: 16px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }

.benefitsOfDecarbonizer {
  padding: 24px 60px;
  margin-top: 70px;
  h3 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
  }
  .benefitsList {
    margin-top: 24px;
    .benefitsListRow {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      .benefitsListItem {
        display: flex;
        align-items: flex-start;
        img {
          width: 80px;
        }
        div {
          width: 300px;
          margin-left: 20px;
          p {
            white-space: nowrap;
            &:first-child {
              font-size: 20px;
              font-weight: 600;
              line-height: 32px;
              margin-bottom: 10px;
            }
            &:last-child {
              font-size: 17px;
              line-height: 29px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
  
.documentationBlock {
  background-color: rgba(45, 90, 168, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 60px;
  h2 {
    color: #fff;
    font-size: 25px;
    line-height: 48px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }
  .whiteButton {
    background-color: white !important;
    color: rgba(45, 90, 168, 1) !important;
    border-color: white !important;
  }
}

.questionsBlock {
  padding: 50px 60px;
  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 24px;
  }
  .questionsItem {
    box-shadow: 18px 15px 35px 0px rgba(0, 0, 0, 0.09);
    border-radius: 20px;
    padding: 32px 40px;

    .questionsTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
      img {
        width: 19px;
      }
    }
  }
}

.content {
    transition: all 0.3s ease-out;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    cursor: pointer;

    &.expanded {
      opacity: 1;
      margin-top: 15px;
      margin-bottom: 15px;
    }
}

.authApiBlock {
  padding: 24px 60px;
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff;

  @media screen and (max-width: 1025px) {
    padding: 24px 40px;
  }

  @media screen and (max-width: 600px) {
    padding: 24px 20px;
  }
}

.headerTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.text {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.connectBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  > span {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;

    > button {
      width: 250px;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      @media screen and (max-width: 500px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 720px) {
    gap: 15px;
  }
}

.accountWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.accountBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.accountBoxWallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  > img {
    margin-top: -1px;
  }
}

.bold {
  font-weight: 600;
}

.balance {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: rgb(24, 57, 122);
  }
}

.modalText {
  padding: 20px 0;
  line-height: 24px;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filterWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 645px) {
    flex-direction: column;
  }
}

.filterSearchWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.filterBtnsWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 645px) {
    flex-direction: column;
  }
}

.search {
  width: 100%;

  > input,
  > span {
    height: 50px;
  }
}

.filterButton {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 100%;
  border-radius: 12px !important;
  border: 1px solid #e0e3e7 !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterWideButton {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 100%;

  @media (max-width: 800px) {
    width: 120px !important;
    min-width: 120px !important;
  }

  @media (max-width: 645px) {
    width: 100% !important;
    min-width: 100% !important;
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.dropdownItem {
  padding: 7px 5px;
  gap: 4px;

  &:hover {
    h2 {
      color: #18397a;
    }
  }
}

.dropdownLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.dropdownDesc {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.termsText {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
}
