@import '../../styles/_mixins.scss';

.pageContent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.pageContentBox {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  padding-top: 20px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;

  @include for-desktop-up-max_1440 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  @include for-mobile-max_767 {
    flex-wrap: wrap;
  }
}
