.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mainBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.counter {
  display: flex;
  gap: 10px;

  > span {
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    text-align: center;
  }
}

.icon {
  svg > path {
    fill: #d32f2f !important;
  }
}

.btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
