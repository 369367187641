@import-normalize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('./assets/fonts/montserrat/montserrat-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('./assets/fonts/montserrat/montserrat-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('./assets/fonts/montserrat/montserrat-bold.ttf') format('truetype');
}

#root {
  height: 100%;
}

.main-button {
  margin: 0 auto;
  max-width: max-content;
  background: none;
  border: 1px solid #ffffff;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 15px 76px;
  color: #ffffff;
  cursor: pointer;
}

.main-button:disabled {
  /* color: #FFFFFF;
  background-color: #8C9DBD; */
  opacity: 0.5 !important;
  border: none;
  cursor: default;
}

.blur-content {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

// Icons colors style
.icon-blue-fill {
  svg > path {
    fill: #18397a !important;
  }
}

.icon-blue-stroke {
  svg > path {
    stroke: #18397a !important;
  }
}

.icon-red-fill {
  svg > path {
    fill: #d32f2f !important;
  }
}

.icon-red-stroke {
  svg > path {
    stroke: #d32f2f !important;
  }
}

.icon-green-fill {
  svg > path {
    fill: #4caf50 !important;
  }
}

.icon-green-stroke {
  svg > path {
    stroke: #4caf50 !important;
  }
}

.icon-white-fill {
  svg > path {
    fill: #ffffff !important;
  }
}

.icon-white-stroke {
  svg > path {
    stroke: #ffffff !important;
  }
}

/* editor override styles */

.ce-settings {
  left: -130px !important;
  z-index: 50;
}

.ce-render-block {
  margin: 10px 0;
}

.ce-paragraph--center {
  text-align: center;
}

.ce-paragraph--left {
  text-align: left;
}

.ce-paragraph--right {
  text-align: right;
}

.codex-editor__redactor {
  padding-bottom: 270px !important;
}

.image-tool {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-tool--withBorder {
  border: 1px solid var(--border-color);
}

.image-tool--withBackground {
  padding: 15px;
  background: var(--bg-color);
}

.image-tool--stretched {
  width: 100%;
}

.image-tool--stretched > img {
  width: 100%;
}

.image-tool--empty {
  align-items: unset !important;
}

/* override mui modal */
.menuItem__title {
  display: none;
}

.leaflet-popup-close-button {
  margin-top: 10px;
  margin-right: 10px;
}

.leaflet-popup-close-button span {
  font-size: 42px !important;
}

.MuiModal-root {
  z-index: 1000 !important;
}

html > #detach-button-host {
  z-index: -1 !important;
}

// post editor style override

.tox * {
  user-select: auto !important;
}

.tox-dialog-wrap {
  z-index: 2000 !important;
}

.mce-content-body::before {
  left: 5px !important;
  padding-left: 5px !important;
}
