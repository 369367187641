.modalContainer {
  background-color: #ffffff;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.sliderContainer {
  margin-top: 15px;
  width: 200px;
}

.rotateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 15px;
  margin-top: 15px;

  > button {
    border-radius: 50% !important;
    width: 50px !important;
    height: 45px !important;
    padding: 10px !important;
  }
}

.modalButtons {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
