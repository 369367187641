.navbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 900;
  width: 100%;
  background-color: #fff;
  padding: 0 130px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);

  @media only screen and (max-width: 1500px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media only screen and (max-width: 1200px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media only screen and (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (max-width: 480px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mobileHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}

.navbarLogo {
  position: relative;
  cursor: pointer;
}

.logoImg {
  width: 70px;
  height: 70px;
}

.navbarLinks {
  font-family: 'Montserrat' !important;
  display: flex;
  z-index: inherit;
  align-items: center;
  column-gap: 20px;
  height: 80px;
  margin-right: 200px;
  margin-left: 20px;
  // > div {
  //   display: flex;
  //   z-index: inherit;
  //   align-items: center;
  //   column-gap: 20px;
  //   height: 80px;
  // }

  @media only screen and (max-width: 1100px) {
    margin-right: 25px !important;
    margin-left: 0;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.navbarLogoSearchBox {
  display: flex;
  align-items: center;
  column-gap: 24px;

  @media only screen and (max-width: 1200px) {
    column-gap: 5px;
  }
}

.navbarLinkImage {
  margin-right: 13px;
  transition: 0.2s;
  width: 20px;
  height: 20px;
}

.mobileLinks {
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
}

.mobileOption {
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    display: block;
  }
}

.forMobileMenu {
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    z-index: 9999;
    top: 70px;
    right: 0;
  }
}

@media screen and (max-width: 1700px) {
  .navbarLinks {
    margin-right: 100px;
  }
}

@media screen and (max-width: 1550px) {
  .navbarLinks {
    margin-right: 0;
  }
}

@media screen and (max-width: 1340px) {
  .navbarLinks {
    column-gap: 5px;
  }
}

@media screen and (max-width: 520px) {
  .mobileMenuRow {
    column-gap: 10px;
  }

  .navbarLogoSearchBox {
    column-gap: 12px;
  }

  .logoImg {
    width: 40px;
    height: 40px;
  }

  .navbarContent {
    height: 76px;
  }
}

.notificButton {
  border: none;
  padding: 14px 10px;
  background-color: transparent;
  cursor: pointer;

  svg > path {
    fill: #212121;
  }

  @media screen and (max-width: 550px) {
    padding: 11px;
  }
}

.notificButtonActive {
  border: none;
  padding: 14px 15px;
  border-radius: 12px;
  background: #143471;
  cursor: pointer;

  @media screen and (max-width: 550px) {
    padding: 11px;
  }
}

.notificIcon {
  transition: 0.2s;
  width: 24px;
}

.notificIconActive {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  width: 24px;
}

.mobileAvatarContainer {
  cursor: pointer;
}

.signNavbarButton {
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  width: 150px;
  padding: 0 20px;
  border-radius: 4px;
  margin-left: 15px;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
    height: 40px !important;
    padding: 0 12px !important;
    width: 111px !important;
    margin-left: 0 !important;
  }

  @media only screen and (max-width: 1024px) {
    display: none !important;
  }
}

.mobileBtnsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
