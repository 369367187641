.participant {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.micIcon {
  position: absolute;
  width: 52px;
  height: 28px;
  left: 20px;
  top: 20px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.userName {
  position: absolute;
  height: 28px;
  left: 20px;
  bottom: 20px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
  border-radius: 4px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.video {
  width: 100%;
  height: 100%;
}
.audio {
  width: 100%;
  height: 100%;
}
.videoOffContent {
  background: #555555;
  padding: 48px !important;
  min-height: 240px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userAvatar {
  display: flex;
  justify-content: center;
}
.webOnline {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-top: 12px;
  text-align: center;
}
.videoHide {
  display: none;
}
