.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;

  > p,
  > div {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  > div {
    text-decoration: underline;
    color: #18397a;
    cursor: pointer;
  }
}
