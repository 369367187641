.questionsItem {
  box-shadow: 18px 15px 35px 0px rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  padding: 32px 40px;

  .questionsTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
    img {
      width: 19px;
    }
  }
  .content {
    transition: all 0.3s ease-out;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    cursor: pointer;

    .answerBlock {
      ol {
        margin-left: 20px;
      }
    }

    .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
    }

    li {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      margin-top: 10px;
    }

    &.expanded {
      opacity: 1;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  } 
}