.DeleteKeyTitleBlock {
  .DeleteKeyTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
}

.DeleteKeyContent {
  margin-top: 16px;
  margin-bottom: 32px;
  .DeleteKeyDescription {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  }
}

.DeleteKeyButtons {
  display: flex;
  justify-content: space-between;
}