.menuContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.container {
  height: max-content;
  min-width: min-content;
  width: 200px;
  max-width: 100%;
  left: 0;
  top: 0;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 24px 0 #0000000a;
  display: flex;
  flex-direction: column;
  justify-self: center;

  &:last-child {
    max-height: calc(100vh - 460px) !important;
    @media screen and (max-height: 1500px) {
      overflow-y: auto;
    }
  }
}

.linksRow {
  padding: 12px 0 12px 0;

  div {
    margin-right: 0;
  }
}

.successKyc,
.pendingKyc {
  border-radius: 8px;
  margin-left: 2px;
  font-weight: 700;
  font-size: 7px;
  line-height: 7px;
  color: #ffffff;
  padding: 2.5px;
}

.successKyc {
  background: #4caf50;
}

.pendingKyc {
  background: #757575;
}

.activeLink {
  display: block;
  width: 190px;
  color: #ffff;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.06);
  padding: 6px 0 4px 20px;
  margin-right: 0;
  background: #143471;
  border-radius: 0;
}

.disabledLink {
  color: #909090 !important;
}

.links {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  cursor: pointer;
  transition: all 0.17s ease;
  padding: 4px 20px 4px 20px;
  display: block;
}

.links:hover {
  color: #143471;
}

.content {
  padding: 0px 10px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  border-bottom: 1px solid #efefef;
}

.avatar {
  margin-top: 18px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invite {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #757575;
  text-align: center;
}

.row {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5px;
}

.name {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
  max-width: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameMark {
  font-weight: 700;
  font-size: 6px;
  line-height: 7px;
  color: #ffffff;
  background: #4caf50;
  border-radius: 8px;
  padding: 2.5px;
  margin-left: 1px;
  position: absolute;
  top: -5px;
  right: -20px;
}

.inviteMark {
  font-size: 6px;
  background: #d32f2f;
  border-radius: 2px;
  padding: 1.4px 5.4px;
  color: white;
  margin-left: 3px;
}

.ceoImg {
  margin-right: 4px;
}

.ceo {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
}

.ceoMark {
  position: absolute;
  top: 1px;
  right: -13px;
}
