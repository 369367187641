.navbarLink {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #555555;
  font-size: 0.97vw;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  background: #ffffff;
  padding: 0 20px;
  border: 0;
  height: 100%;

  &:hover {
    color: #143471;
  }
}

.navbarLinkActive {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  color: #ffff;
  background: #143471;
  border-radius: 0px;
  border: 0;
  cursor: pointer;
  height: 100%;
}

.linkIcon {
  transition: 0.2s;
  margin-bottom: 5px;
  width: 20px;
  height: 20px;
}

.linkIconActive {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.navbarLinktext {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  transition: 0.25s;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
}

@media screen and (max-width: 1340px) {
  .navbarLink {
    padding: 0px 10px;
  }
  .navbarLinkActive {
    padding: 0px 10px;
  }
}

.notification, .notificationUnread {
  display: flex;
  margin-top: 2px !important;
  min-width: 320px;
  max-width: 320px;
}
.notificationUnread {
  background-color: rgba(24, 57, 122, 0.1);
}

.loader {
  min-width: 320px;
  max-width: 320px;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationTitle {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
}

.notificationInfo {
  width: calc(100% - 56px);
  margin-left: 16px;
}

.notificationDescription {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
  width: 100%;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  p {
    a {
      text-decoration: underline;
      margin: 0 5px;
      color: #18397A;
    }
  }
}
.unreadIcon {
  svg > path {
    fill: #18397A;
  }
}
.viewAllLinkBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  margin-top: 12px;

  &:hover {
    background-color: transparent;
  }
}

.viewAllLink {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.viewAllLinkText {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #18397a;
  margin-right: 8.15px;
}
.date {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #757575;
}
