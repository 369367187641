.formWrapper {
  padding: 10px 0;
}

.formTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-size: 24px;
    font-weight: 600;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}

.closeBtn {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 10px;
}

.body {
  position: relative;
}

.form {
  margin-top: 2px;
  width: 100%;

  > div {
    width: 100%;
  }
}

.roleTextBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  gap: 12px;

  @media screen and (max-width: 772px) {
    width: 100%;
    flex-direction: column;
  }
}

.verticalCenter {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 772px) {
    width: 100%;
  }
}

.pdTopRow {
  margin-top: 12px;
}

.pdTopRow_L {
  margin-top: 16px;
}

.phone {
  height: 48px;
  width: 44px !important;
  background: transparent !important;
  border: none !important;
  border-radius: 16px !important;

  & > div {
    padding: 0 0 0 20px !important;
    margin: 12px 0 12px 0 !important;
    position: initial !important;
    &:focus,
    &:hover {
      background-color: transparent !important;
    }

    &[aria-expanded='true'] {
      background: transparent !important;
    }
  }
}

.phoneInput {
  height: 48px !important;
  width: 100% !important;
  background: #ffffff !important;
  border: 1px solid #efefef !important;
  border-radius: 16px !important;
  font-size: 16px;

  @media screen and (max-width: 772px) {
    font-size: 14px !important;
  }
}

.errorInput {
  border-color: #d32f2f !important;
}

.errorMessage {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
}

.roleDescription {
  margin-top: 10px;
  line-height: 24px;
}

.roleSelect {
  width: 100% !important;

  @media screen and (max-width: 772px) {
    font-size: 14px !important;
  }
}

.formBottom {
  margin-top: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: 460px) {
    flex-direction: column-reverse;
    align-items: inherit;
    margin-top: 12px;
  }

  .rightButtons {
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 460px) {
      flex-direction: column-reverse;
      align-items: inherit;
      margin: 12px 0;
      gap: 12px;
    }

    .googleBtn {
      cursor: pointer;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.formSubmitButton {
  //padding: 0 12px;
  margin: 0 0 0 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  flex-shrink: 0;
  max-width: unset;
  border-radius: 4px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }

  @media screen and (max-width: 370px) {
    line-height: 18px;
    padding: 5px 6px;
  }
}

.formSecondaryButton {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  max-width: unset;
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
    padding: 0 10px;
  }

  @media screen and (max-width: 370px) {
    line-height: 18px;
    padding: 5px 6px;
  }
}

.formDescription,
.formDescriptionRow {
  display: flex;
  flex-direction: column;
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  a {
    color: #143471;
    font-weight: 600;
    text-decoration: underline;
  }

  span:nth-child(1) {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.formDescriptionRow {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 16px;
}

.inputSignupWrapper {
  position: relative;
  flex-grow: 1;
  width: 100%;

  input,
  label {
    font-size: 16px;
  }

  ul {
    width: 275px !important;
    max-height: 175px !important;
    font-size: 14px;
    top: 50px;
  }

  @media screen and (max-width: 772px) {
    > div {
      width: 100%;
    }

    input,
    label {
      font-size: 14px;
    }
  }
}

.formLink {
  font-size: 16px;
  font-weight: 400;
  color: #143471;
  text-decoration: underline;
  cursor: pointer;
  letter-spacing: 0.1px;
  width: fit-content;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    margin-right: 5px;
  }

  @media screen and (max-width: 460px) {
    margin-top: 8px;
  }
}

.section {
  margin-top: 20px;
}

.subTitleCategory {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}

.addFileSection {
  margin-top: 20px;
  align-items: flex-start !important;
}

.buttonWithImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  span {
    color: #143471 !important;
  }
}

.formInput {
  position: relative;
  width: 100%;
  font-size: 16px;
  font-weight: 400;

  input,
  label {
    font-size: 16px;
  }

  @media screen and (max-width: 772px) {
    input,
    label {
      font-size: 14px;
    }
  }

  & > label {
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-style: normal;
      font-weight: 400;
    }
  }
}

.formError {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: unset;
  color: #d32f2f;
}

.passwordValidationWrapper {
  margin-bottom: 20px;

  > div {
    margin: 5px 13px;
    letter-spacing: 0.03333em;
  }
}

.policyWrapper {
  margin-top: 18px;
  margin-left: 5px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  > label {
    margin: -6px !important;

    @media screen and (max-width: 380px) {
      span {
        font-size: 14px !important;
      }

      span:nth-child(1) {
        padding: 2px !important;
      }
    }
  }
}

.checkboxWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  & + label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #757575;
    border-radius: 2px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 60%;
    cursor: pointer;
  }

  &:checked + label::before {
    background-color: #757575;
  }
}

.partInput {
  color: #000000;
  border-color: #c4c4c4;
}

.codeDesc {
  line-height: 24px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
