.bookCallTitleBlock {
  display: flex;
  .bookCallTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
}

.modal_input {
  width: 50%;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 815px) {
    width: 100% !important;
  }
}

.select_container {
  @media screen and (max-width: 815px) {
    width: 100% !important;
  }
}

.errorMessage {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: red;
  line-height: 17px;
}

.modal_email {
  height: 48px;
  width: 772px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  text-align: start;
  padding-left: 10px;
  padding-top: 7px;
}

.inputLabel {
  text-decoration: none;
  /* padding: 25px; */
  /* padding-left: 30px; */
  margin-left: -5px !important;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px !important;
  letter-spacing: 0;
  text-align: left;
}

.modal_content_first {
  padding-top: 12px;
}

.modal_selectInput {
  border-radius: 12px !important;
  width: 150px;

  @media screen and (max-width: 815px) {
    width: 100% !important;
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 12px;

  @media screen and (max-width: 815px) {
    flex-direction: column !important;
  }
}

.modal_textArea {
  width: 100%;
  height: 120px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  border-radius: 12px;
  margin-bottom: 12px;
}

.modal_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.modal_buttonCancel {
  display: flex;
  justify-content: flex-start;
  width: 69px;
  height: 48px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  background-color: white;
  color: #18397a;
  border: none;
  outline: none;
}

.modal_buttonSend {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 48px;
  width: 130px;
  border: none;
}
