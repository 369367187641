.callTimeoutModal {
  display: flex;
  flex-direction: column;
}

.modalContent {
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.modalActions {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
