.mainBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  
  .mainBlockInfo {
    max-width: 480px;
    text-align: left;
    margin-right: 15px;
      h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
        color: rgba(33, 33, 33, 1);
        margin-bottom: 1rem;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        color: rgba(33, 33, 33, 1);
        line-height: 24px;
        margin-bottom: 1rem;
      }
      ul {
        list-style-type: disc;
        padding-left: 1.5rem;
        margin-bottom: 1rem;
      }
      ul li {
        margin-bottom: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        color: rgba(33, 33, 33, 1);
        line-height: 24px;
      }
  }
}