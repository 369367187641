.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 32px;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
}

.dashboardModalBody {
  margin-top: 15px;

  * {
    color: inherit !important;
    font-size: 16px;
  }
}
