@import '/src/styles/mixins';

.dialogFooter {
  justify-content: center !important;
  padding: 8px 20px !important;
}
.dialogActions {
  flex: 1;
  display: flex;
  justify-content: center !important;
  gap: 24px;
  @include for-mobile-max_480 {
    gap: 16px;
  }
}
.dialogLeftActions {
  display: flex;
  justify-content: center !important;
  gap: 24px;
}
.actionButton {
  border-radius: 100%;
  width: 40px;
  height: 40px;

  @include for-mobile-max_480 {
    width: 35px;
    height: 35px;
    img {
      width: 11px !important;
      height: 11px !important;
    }
  }
}
.tooltip {
  background-color: #ffffff;
  color: #5c5c5c;
}
.actionButtonGreen {
  background-color: #4caf50 !important;
}
.actionButtonRed {
  background-color: #d32f2f !important;
}
.actionButtonDisabled {
  background-color: #8c9dbd !important;
}
.actionButtonBlue {
  background-color: #18397a !important;
}
.timer {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #212121;
  min-width: 85px;
}
.warningIcon {
  position: absolute;
  top: -3px;
  right: -7px;
}
