.mobileNavbar {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 34px rgba(0, 0, 0, 0.08);
  align-items: center;
  z-index: 9999;

  @media screen and (max-width: 500px) {
    padding-left: 15px;
  }
}
