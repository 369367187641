.avatarOverlay {
  position: relative;

  &:hover {
    .avatarRow {
      box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.06);
      border-radius: 12px 12px 0 0;
    }
  }
}

.avatarRow {
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.disabledLink {
  color: #909090 !important;
}

.avatar {
  width: 40px;
  height: 40px;
}

.dropdown {
  position: absolute;
  z-index: 3231;
  top: 0;
  right: -5px;
  background-color: white;
  width: max-content;
  padding: 13px 0 18px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.13);
  border-radius: 0 0 12px 12px;
  margin: 0;

  .notActiveDropdownItem {
    color: #212121;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 57px 6px 19px;
    list-style-type: none;
    position: relative;
    cursor: auto;
    &:last-child {
      color: #d32f2f;
      margin-top: 20px;
      border-top: 1px solid #efefef;
    }
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.dropdownNestedRoot {
  max-width: 250px !important;
  padding: 6px 19px !important;

  &:hover {
    color: #212121 !important;
    background: #ffffff !important;
  }
}

.dropdownNestedRootDrawer {
  max-width: 250px !important;
  padding: 0 !important;
  margin-bottom: 12px;

  &:hover {
    color: #212121 !important;
    background: #ffffff !important;
  }
}

.dropdownTitle {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.dropdownItem {
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 57px 6px 19px;
  list-style-type: none;
  position: relative;

  span {
    margin-right: 6px;
  }

  &:last-child {
    color: #d32f2f;
    margin-top: 20px;
    border-top: 1px solid #efefef;
  }

  &:hover {
    color: #ffffff;
    background: #143471;
  }
}

.dropdownText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
}

.dropdownBox {
  position: relative !important;
  background: #ffffff;
  border-radius: 0 0 12px 12px;
  display: block;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.settingModal {
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
}
.downArrowDiv {
  position: absolute;
  bottom: -2px;
  right: -2px;
  z-index: 4;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  padding: 3px 5px 2px 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow .arrowActive {
  width: 9px;
  height: 20px;
  margin-top: 5px;
}

.arrowActive {
  margin-top: -2px;
  path {
    fill: #ffffff;
  }
}

.dropdownCollapse {
  display: flex;
  align-items: center;
  position: relative;
}

.dropdownBody {
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdownArrowCollapse {
  position: absolute;
  right: 10px;
}

.dropdownItemCollapse {
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 57px 6px 19px;
  list-style-type: none;
  position: relative;
}

.dropdownItemCompany {
  margin: 0;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-left: 22px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;

  li {
    font-weight: 500;
    list-style-type: none;
    width: 98px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &:hover {
    color: #ffffff;
    background: #143471;
  }
}

@media screen and (max-width: 765px) {
  .avatarRow {
    padding: 0;
  }

  .downArrowDiv {
    top: 25px;
    right: -2px;
  }
}

.avatarBadge {
  position: absolute;
  bottom: -8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  background: #fcfcfc;
  border-radius: 6px;
  padding: 3px 4px;
}

.modalDialogBlock {
  padding: 0 !important;
  height: 100%;
}

.modal_header,
.modal_footer {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.modal-header {
  border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
}

.modal_footer {
  border-top: 2px solid #dbdbdb;
  justify-content: flex-end;
}

.modal_close {
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal_body {
  overflow: auto;
}

.modal_content {
  padding: 1rem;
  margin: 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.user {
  display: flex;
  align-items: center;
  column-gap: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #757575;
  margin-bottom: 20px;
}

.userInfo {
}

.avatarRow {
  position: relative;
}

.parent {
  &:hover {
    color: #143471;

    .nestedLink {
      display: block;
    }
  }
}

.nestedLink {
  color: #212121;
  display: none;
  margin-left: 14px;
  margin-bottom: 12px;
}

.ProfileLinks {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #efefef;
}

.role {
  position: absolute;
  bottom: -8px;
  right: 5.5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #d8913c;
  background: #fcfcfc;
  border-radius: 6px;
  padding: 1.5px 6.5px 5px 7.5px;
}

.ceoRow {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.name {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  width: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkMark {
  position: absolute;
  top: -5px;
  right: -10px;
}

.linksRow {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  margin-bottom: 12px;
  cursor: pointer;
}

@media screen and (max-width: 375px) {
  .modal_dialog {
    width: 260px;
  }

  .modal_dialog .modal_dialog_block {
    padding: 0;
  }

  .linksRow {
    font-size: 16px;
    line-height: 24px;
  }
}
