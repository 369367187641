@import '../../styles/mixins';

.wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalRoot {
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(14px);
}

.homeMap {
  width: 100%;
  height: 20rem;
}

.mapWrapper {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    height: 100%;
  }
}

.title {
  align-self: flex-start;
  margin-bottom: 24px;

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #212121;
}

.buttonsWrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include for-mobile-max_767 {
    flex-direction: column;
    gap: 16px;
  }

  @include for-mobile-max_480 {
    width: 100%;
  }
}

.firstBtn {
  background: transparent;
  color: #ffffff;

  &.profile {
    border: 2px solid #18397a;
    color: #18397a;
  }
}

.secondBtn {
  background: #ffffff;
  color: #333333;

  &.profile {
    background: #18397a;
    color: #ffffff;
  }
}

.firstBtn,
.secondBtn {
  padding: 13px 28px;
  gap: 28px;
  min-width: 146px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border: 2px solid #ffffff;

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
  }

  &:disabled {
    background: lightgray;
    color: grey;
    border: none;
  }

  @include for-mobile-max_767 {
    font-size: 14px;
  }
}
