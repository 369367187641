.modalBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialogContent {
  padding: 0 !important;
  min-height: 240px;
  position: relative;
  overflow: hidden;
  background: #f9f9f9;
}
.remoteParticipants {
}
.localParticipant {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  width: 100px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.dialogContentPendingCall {
  justify-content: center;
  background: #555555;
  padding: 48px !important;
  min-height: 240px;
}
.userAvatar {
  display: flex;
  justify-content: center;
}
.webOnline {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-top: 12px;
  text-align: center;
}
