.GenerateKeyTitleBlock {
  .GenerateKeyTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
}

.GenerateKeyContent {
  margin-top: 16px;
  margin-bottom: 32px;
  .GenerateKeyDescription {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  }
}

.GenerateKeyButtons {
  display: flex;
  justify-content: space-between;
}