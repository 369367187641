.DAloaderIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 35px;

  > div {
    > span {
      width: 70px !important;
      height: 70px !important;
      flex-shrink: 0 !important;
      color: #c4c4c4 !important;

      > svg {
        width: 70px !important;
        height: 70px !important;
      }
    }
  }
}
