.authApiBlock {
  padding: 24px 60px;
}

.authDecarbonizatorBlock {
  margin-top: 80px;
  h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
  }
}

.decarbonizatorBlock {
  box-shadow: 18px 15px 35px 0px rgba(0, 0, 0, 0.09);
  border-radius: 16px;
  padding: 26px;
  margin-top: 24px;
  cursor: pointer;
  &:hover {
    box-shadow: 18px 15px 35px 0px rgba(0, 0, 0, 0.15);
  }
  .decarbonizatorBlockTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: fit-content;
      height: fit-content;
      background-color: rgba(76, 175, 80, 1);
      padding: 5px 12px;
      border-radius: 12px;
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #fff;
      }
    }
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
}