.dialogHeader {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px 12px 0px 0px;
  padding: 0 25px;
  cursor: move;
}
.dialogStatus {
  width: 20%;
  font-weight: 400;
  font-size: 12px;
  color: #212121;
  position: relative;
  display: flex;
}
.dialogTitle {
  width: 60%;
  flex: 1;
  text-align: center;
  color: #212121;
  font-weight: 600;
  font-size: 16px;
}
.dialogResizer {
  margin-left: auto;
}
.timer {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #212121;
  min-width: 85px;
}

@media screen and (max-width: 600px) {
  .dialogResizer {
    display: none;
  }
}
