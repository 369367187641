@import '/src/styles/mixins';

.btn {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: #18397a !important;
  @include for-mobile-max_480 {
    width: 35px;
    height: 35px;
    img {
      width: 11px !important;
      height: 11px !important;
    }
  }
}

.callBtnContent {
  position: relative;
}

.dropdownBox {
  position: absolute !important;
  left: 0;
  bottom: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.dropdown {
}
